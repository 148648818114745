/* eslint-disable no-magic-numbers */
import React, { useEffect, useRef, useState } from 'react';
import { useCallback } from 'react';

import { IdToken, useAuth0 } from '@auth0/auth0-react';
import { datadogRum } from '@datadog/browser-rum';
import { GateProvider } from '@nestoca/gate-react';
import jwtDecode from 'jwt-decode';
import { useRouter } from 'next/router';

import { client as apiClient } from 'libs/api';
import logger from 'logger/logger';
import { useAccountRid } from 'providers/account-rid/use-account-rid';
import { TokenRole } from 'providers/auth/types';
import { userRoleHomeRoute } from 'providers/auth/utils';
import { setClientContext, setUserIdentity } from 'utils/dog';
import { useAnalytics } from 'utils/use-analytics';

import { UserContext, UserContextState } from './user-context';

interface UserProviderProps {
    children: React.ReactNode;
}

const getAccountForRole = async (role: TokenRole) => {
    let account: UserContextState['account'] | undefined;
    switch (role) {
        case 'broker':
            account = await apiClient
                .getBrokerAccount()
                .then(({ data, status }) => {
                    if (status >= 400) {
                        throw new Error('Error fetching broker account');
                    }
                    return data;
                })
                .catch((error) => {
                    throw error;
                });
            break;
        case 'realtor':
            account = await apiClient
                .getRealtorAccount()
                .then(({ data, status }) => {
                    if (status >= 400) {
                        throw new Error('Error fetching realtor account');
                    }
                    return data;
                })
                .catch((error) => {
                    throw error;
                });
            break;
        case 'financialadvisor':
            account = await apiClient
                .getFinancialAdvisorAccount()
                .then(({ data, status }) => {
                    if (status >= 400) {
                        throw new Error(
                            'Error fetching financialadvisor account'
                        );
                    }
                    return data;
                })
                .catch((error) => {
                    throw error;
                });
            break;
    }

    return account;
};

// The getAccessTokenSilently() method can renew the access and ID token for you
// https://auth0.com/docs/quickstart/spa/react/02-calling-an-api#react-containers-AlertContainer-6
const tokenRefresher = async (
    getAccessTokenSilently: () => Promise<string>
) => {
    const token = await getAccessTokenSilently();
    apiClient.setAuthToken(token);
};

const TOKEN_TTL = 5 * 60 * 1000; // 5 minutes
const startTokenRefresher = (
    getAccessTokenSilently: () => Promise<string>,
    ms = TOKEN_TTL
) => {
    return setInterval(() => tokenRefresher(getAccessTokenSilently), ms);
};

export const UserProvider = ({ children }: UserProviderProps) => {
    const { addError } = useAnalytics();
    const router = useRouter();
    const { accountRid } = useAccountRid();
    const { isLoading, getAccessTokenSilently, isAuthenticated, user } =
        useAuth0();

    // persist for the full lifetime of the component.
    // avoid fetching when the component is re-redendered
    const inFlight = useRef(false);

    const [token, setToken] = useState<UserContextState['token']>();
    const [permissions, setPermissions] = useState<
        UserContextState['permissions']
    >([]);
    const [client, setClient] = useState<UserContextState['client']>(undefined);
    const [account, setAccount] =
        useState<UserContextState['account']>(undefined);
    const authInterval = useRef<ReturnType<typeof setInterval> | undefined>(
        undefined
    );

    const checkToken = useCallback(async () => {
        try {
            if (!isAuthenticated) {
                return;
            }

            const token = await getAccessTokenSilently();
            const { exp, permissions } = jwtDecode<IdToken>(token);
            setToken(token);
            setPermissions(permissions);
            if (!exp) return;
            const willExpireInFiveMinutes = Date.now() >= (exp - 5 * 60) * 1000;
            if (willExpireInFiveMinutes) {
                const newToken = await getAccessTokenSilently({
                    cacheMode: 'off',
                });
                setToken(newToken);
                setPermissions(permissions);
            }
        } catch (error) {
            /**
             * Handle errors such as `login_required` and `consent_required` by re-prompting for a login
             * Auth0 will automatically do this when the user's token expires
             *
             * @see https://nestoca.atlassian.net/browse/OG-6997
             * @see https://github.com/auth0/auth0-react/blob/75ad76e01672fc533fbed2fb63d9887fd83d6fc4/EXAMPLES.md?plain=1#L63-L79
             * @see https://community.auth0.com/t/why-is-authentication-lost-after-refreshing-my-single-page-application/56276
             */
            let message = 'Unknown error';
            let stack: string | undefined;
            if (error instanceof Error) {
                message = error.message;
                stack = error.stack;
            }

            // Send to DD to track how often this happens
            datadogRum.addAction('login_required', {
                message,
                stack,
            });
        }
    }, [getAccessTokenSilently, isAuthenticated]);

    useEffect(() => {
        checkToken();
        const interval = setInterval(checkToken, 1000 * 5);

        return () => clearInterval(interval);
    }, [checkToken]);

    useEffect(() => {
        if (!isAuthenticated) {
            return;
        }

        // wait for the inFlight to be false
        // we dont want to fetch the token and or backend twice
        if (inFlight.current) {
            return;
        }

        (async () => {
            try {
                inFlight.current = true;
                const token = await getAccessTokenSilently();
                const { permissions, ...restDecoded } = jwtDecode<
                    IdToken & { permissions: string[] }
                >(token);

                apiClient.setAuthToken(token);
                // Token refresher
                if (authInterval.current) {
                    clearInterval(authInterval.current);
                }
                authInterval.current = startTokenRefresher(
                    getAccessTokenSilently
                );

                setToken(token);
                setPermissions(permissions);

                // Get nesto role from Auth0 token before we can fetch the account
                const tokenRole = restDecoded[
                    'http://n.ca/user_role'
                ] as TokenRole;

                const allowedRoles = [
                    'broker',
                    'advisor',
                    'realtor',
                    'financialadvisor',
                ];

                if (!allowedRoles.includes(tokenRole)) {
                    throw new Error('Middle Office: Invalid user role');
                }

                const isBroker = tokenRole === 'broker';

                // Only broker have to fetch with the header `x-on-behalf-of`
                if (!isBroker) {
                    // Why not using the `resetAccountRid` from `useAccountRid`?
                    // const { resetAccountRid } = useAccountRid();
                    // it will retrigger this effect and we dont want that
                    // because this account request will already be in flight in
                    // between the reducer event and the user will get a 401
                    // and be redirected on the forbidden page

                    // Making sure to have a clean state if the user come with different role
                    apiClient.resetXOnBehalf();

                    localStorage.removeItem('OfficeAuth:accountRid');
                    localStorage.removeItem('OfficeAuth:applicationId');
                    sessionStorage.removeItem('tenant');
                }

                // if we have account in memory we dont fetch it
                if (!account) {
                    // TODO should we use the unified `/account` endpoint or `/broker`?
                    // Try this if BE support request without `x-on-behalf-of` header
                    // const { data: accountData } = await apiClient.getAccount();
                    // Until we have a support fallback the request to the role specific endpoint
                    const accountData = await getAccountForRole(tokenRole);
                    setUserIdentity(accountData);
                    setAccount(accountData);
                } else {
                    // Set DataDog User Identity
                    setUserIdentity(account);
                }

                if (
                    isBroker &&
                    accountRid &&
                    (!client || client.rid !== accountRid)
                ) {
                    // exising client rid is not the same as the accountRid
                    // Get client account
                    const { data: clientAccount } =
                        await apiClient.getBorrowerAccount();

                    setClientContext(clientAccount);
                    setClient(clientAccount);
                }

                // if role is not broker than we don't want the user on the `/` page
                // because it's a broker only page
                if (router.pathname === '/' && !isBroker) {
                    await router.replace(
                        userRoleHomeRoute[tokenRole] || '/forbidden'
                    );
                    return;
                }

                if (
                    router.pathname !== '/missing-rid' &&
                    isBroker &&
                    !accountRid
                ) {
                    await router.replace('/missing-rid');
                    return;
                }

                if (
                    router.pathname === '/missing-rid' &&
                    isBroker &&
                    accountRid &&
                    client
                ) {
                    await router.replace(userRoleHomeRoute[tokenRole]);
                    return;
                }
            } catch (error) {
                addError(error);

                logger.warn(error);

                // Let's redirect to a page where we don't require authentication
                // replace the unauthorized page with the forbidden page
                // in the browser history
                if (router.pathname !== '/forbidden') {
                    await router.replace('/forbidden');
                }
            } finally {
                inFlight.current = false;
            }
        })();

        return () => {
            if (authInterval.current) {
                clearInterval(authInterval.current);
                authInterval.current = undefined;
            }
        };
        // addError is not a dependency because we don't want to trigger this effect
        // and do an infinite loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, getAccessTokenSilently, inFlight.current, accountRid]);

    if (isLoading || !isAuthenticated) {
        return <>{children}</>;
    }

    // wait authenticated from `getAccessTokenSilently` to be resolved
    // and have all required dependencies loaded before rendering the children
    let hasRequiredDependencies = false;

    switch (account?.role) {
        // Only broker has client
        case 'broker':
            hasRequiredDependencies = Boolean(
                user && client && permissions && account
            );
            break;
        // Partner section doesn't have client
        // we only the account and user/permissions from Auth0
        case 'advisor':
        case 'realtor':
        case 'financialadvisor':
            hasRequiredDependencies = Boolean(user && permissions && account);
            break;
    }

    // wait for all dependencies to be resolved
    // except for:
    // `/forbidden` we check if the dependencies are resolved in the page itself
    // `/missing-rid` will have a missing client
    const apiClientToken = apiClient.getAuthToken();
    if (
        isAuthenticated &&
        !['/forbidden', '/missing-rid'].includes(router.pathname) &&
        (!apiClientToken || !hasRequiredDependencies)
    ) {
        return null;
    }

    return (
        <UserContext.Provider
            value={{
                // Auth0 user and permissions
                user,
                permissions,
                token,
                // nesto logged in user account
                account,
                setAccount,
                // nesto on behalf of client
                client,
                setClient,
            }}
        >
            <GateProvider user={user} permissions={permissions}>
                <>{children}</>
            </GateProvider>
        </UserContext.Provider>
    );
};
