import type { RegisteredAddress } from './address';
import type {
    IncomeEmployment,
    ApplicantAsset,
    ApplicantInfo,
    ExistingIncomeOther,
} from './applicant';
import type { OtherProperty, SubjectProperty } from './property';

export enum DocumentStep {
    All = 0,
    Critical,
    Additional,
}

export type ApplicantDocument = {
    applicantId: number;
    applicationId: number;
    category: DocumentCategory;
    subCategory: DocumentSubCategory;
    files: DocumentFile[];
    documentType: DocumentType;
    entityId: number;
    isDownloadable?: boolean;
    systemVerificationAvailable?: boolean;
    isForced: boolean;
    year?: number;
    step: DocumentStep;
    state?: DocState;
    validEvents?: DocumentEvent[];
    messages: DocumentMessage[];
    validations?: DocumentValidation[];
    rationales?: DocumentMessage[];
    // Frontend only while on create and unsaved
    isNew?: boolean;
    created: string;
    updated: string;
    visibility?: DocumentVisibility;
};

export type DocumentPDFUrl = {
    fileId?: string;
    contentType: string;
    created: string;
    excludeAudit: boolean;
    expiry: string;
    originalFileName: string;
    systemVerificationStatus: boolean;
    updated: string;
    url: string;
};

export type ApplicantNewDocument = Omit<
    ApplicantDocument,
    | 'files'
    | 'step'
    | 'state'
    | 'messages'
    | 'isForced'
    | 'validations'
    | 'created'
    | 'updated'
    | 'systemVerificationAvailable'
>;

export type DocumentFile = {
    contentType: DocumentContentType;
    created?: string;
    fileId?: string;
    originalFileName: string;
    updated?: string;
    uploadKey?: string;
    excludeAudit?: boolean;
    systemVerificationStatus: boolean;
    // From Upload to acquire signed url
    expiry?: Date;
    url?: string;
    // FE only - File to upload when coming from browser selected file
    file?: File;
    // fields that should be omitted from payload to backend
    error?: any;
    uploaded?: boolean;
    uploading?: boolean;
};

export type DocumentFileMoveRequestBody = {
    destinationApplicantId: number;
    destinationDocumentType: DocumentType;
    destinationYear: number;
    destinationEntityId: number;
};

// Copy from FE but maybe it can be mouve out of here because it will be same as
// the new notes services
export type DocumentMessage = {
    id?: number;
    threadId?: string;
    sender: {
        id: number;
        name: {
            firstName: string;
            lastName: string;
        };
    };
    recipient: {
        id: number;
        name: {
            firstName: string;
            lastName: string;
        };
    };
    message: {
        text: string;
    };
    created: string;
    updated: string;
};

export type DocumentContentType = keyof typeof documentContentType;
// TODO move this outta here
export const documentContentType = {
    'application/msword': ['.doc'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
        '.docx',
    ],
    'image/gif': ['.gif'],
    'image/heif': ['.heif'],
    'image/heic': ['.heic'],
    // 'image/jpeg': ['JPEG'],
    'image/jpeg': ['.jpg'],
    'application/x-iwork-keynote-sffkey': ['.key'],
    'application/vnd.oasis.opendocument.presentation': ['.odp'],
    'application/vnd.oasis.opendocument.spreadsheet': ['.ods'],
    'application/vnd.oasis.opendocument.text': ['.odt'],
    'application/pdf': ['.pdf'],
    'image/png': ['.png'],
    'application/vnd.ms-powerpoint': ['.ppt'],
    'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        ['.pptx'],
    'text/plain': ['.txt'],
    'application/vnd.ms-excel': ['.xls'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        '.xlsx',
    ],
};

export type DocState =
    | 'DOCUMENT_EMPTY'
    | 'DOCUMENT_RECEIVED'
    | 'DOCUMENT_BROKER_UNAPPROVED'
    | 'DOCUMENT_DISABLED'
    | 'DOCUMENT_BROKER_APPROVED'
    | 'DOCUMENT_LENDER_ACCEPTED';

export type DocumentEvent =
    | 'BROKER_APPROVE'
    | 'BROKER_ENABLE_EMPTY'
    | 'BROKER_ENABLE_RECEIVED'
    | 'BROKER_DISABLE'
    | 'BROKER_UNAPPROVE'
    | 'LENDER_ACCEPT'
    | 'LENDER_REJECT'
    | 'UPLOAD_FILE'
    | 'DELETE_FILE'
    | 'DELETE_ALL_FILES';

// List of available document type
export type DocumentType =
    | 'ACCOUNT_STMT_CRA'
    | 'ACCOUNT_STMT_RQ'
    | 'ACC_STMT_SELF_BORROW'
    | 'ADJUSTMENT_STMT'
    | 'ALLOWANCE_CHQ_SLIP'
    | 'APPRAISAL_REPORT'
    | 'APPRAISAL_REPORT_MARKET_RENT'
    | 'BANKRUPTCY_DISCHARGE_STMT'
    | 'BANK_STMT'
    | 'BANK_STMT_12_MONTHS'
    | 'BANK_STMT_90_DAYS'
    | 'BANK_STMT_90_DAYS_OTHER_INCOMES'
    | 'BANK_STMT_90_DAYS_PENSION'
    | 'BANK_STMT_ASSETS'
    | 'BANK_STMT_ASSET_DEPOSIT'
    | 'BANK_STMT_DEPOSIT_CONFIRMATION'
    | 'BIRTH_CERTIFICATE'
    | 'BUSINESS_LIC'
    | 'CONDO_FEES'
    | 'CONDO_STATUS_CERTIFICATE'
    | 'CONSUMER_PROP_DISCHARGE_STMT'
    | 'CREDITOR_LETTER'
    | 'CREDITOR_STUDENT_LOAN_INTEREST'
    | 'CREDIT_HISTORY_12M'
    | 'CREDIT_REPORT'
    | 'DEATH_CERTIFICATE_INHERITANCE'
    | 'DEPOSIT_CONFIRMATION'
    | 'DIVORCE_AGRM_CHILD_SUPPORT_LIABILITIES'
    | 'DIVORCE_AGRM_SPOUSAL_SUPPORT_LIABILITIES'
    | 'DEBT_PAYMENT'
    | 'EMPLOYMENT_LETTER'
    | 'FED_NOTICE_OF_ASSESSMENT_MOST_RECENT'
    | 'FED_NOTICE_OF_ASSESSMENT_PREVIOUS_YEAR'
    | 'FINANCIAL_STMT_MOST_RECENT'
    | 'FINANCIAL_STMT_PREVIOUS_YEAR'
    | 'FINDERS_FEE_DISCLOSURE'
    | 'FINAL_LENDER_REQUIRED'
    | 'GIFT_LETTER'
    | 'GRC62'
    | 'HOME_INSURANCE_POLICY'
    | 'HST_REMITTANCE_STMT'
    | 'INCORPORATION_ARTICLE'
    | 'INSPECTION_REPORT'
    | 'INVESTMENT_ACC_BAL'
    | 'LANDLORD_LETTER'
    | 'LEASE_AGREEMENT'
    | 'LIFE_INSURANCE_POLICY'
    | 'LOCALISATION_CERTIFICATE'
    | 'MLS_LISTING'
    | 'MORTGAGE_STATEMENT'
    | 'MUNICIPAL_TAX_BILL'
    | 'NON_PHOTO_ID'
    | 'NOTICE_OF_CREDIT_CHARGES'
    | 'NOTICE_OF_FULFILLMENT'
    | 'OTHER_INVESTMENT_ASSETS'
    | 'OTHER_DOCUMENT'
    | 'PAYSTUB'
    | 'PENDING_SALE_AGREEMENT_OF_PURCHASE_AND_SALE'
    | 'PENDING_SALE_MLS_LISTING'
    | 'PENDING_SALE_MORTGAGE_STATEMENT'
    | 'PHOTO_ID'
    | 'PROV_NOTICE_OF_ASSESSMENT_MOST_RECENT'
    | 'PROV_NOTICE_OF_ASSESSMENT_PREVIOUS_YEAR'
    | 'PURCHASE_AGREEMENT'
    | 'PURCHASE_AMMENDMENT'
    | 'REALTOR_CONTACT_INFO'
    | 'RRSP_STMT_ASSETS'
    | 'RRSP_WITHDRAW_CONFIRMATION'
    | 'SALE_ASSET_LISTING'
    | 'SCHOOL_TAX_BILL'
    | 'SEPARATION_AGREEMENT'
    | 'SIGNED_DISCLOSURE'
    | 'SIGNED_EXCLUSIVITY_AGREEMENT'
    | 'SIGNED_MORTGAGE_APPROVAL'
    | 'SIGNED_MORTGAGE_INS_WAIVER'
    | 'SIGNED_SUITABILITY_CONSENT_FORM'
    | 'SIGNED_CLOSING_DOCUMENTS'
    | 'SOLICITOR_CONTACT_INFO'
    | 'SOLICITOR_INSTRUCTIONS'
    | 'STRATA_B'
    | 'T1_MOST_RECENT'
    | 'T1_MOST_RECENT_OTHER_INCOMES'
    | 'T1_PREVIOUS_YEAR'
    | 'T1_PREVIOUS_YEAR_OTHER_INCOMES'
    | 'T4A'
    | 'T4_MOST_RECENT'
    | 'T4_PREVIOUS_YEAR'
    | 'T5_MOST_RECENT'
    | 'T5_PREVIOUS_YEAR'
    | 'TFSA_STMT_ASSETS'
    | 'UCCB'
    | 'VOID_CHECK'
    | 'WATER_PORTABILITY_TEST'
    | 'ZERO_BALANCE_CONFIRMATION'
    | 'PRE_APPROVAL_LETTER'
    | 'FINAL_LETTER'
    | 'BRIDGE_LOAN_APPROVAL_LETTER'
    | 'HELOC_LOAN_AGREEMENT'
    | 'LOAN_AGREEMENT_EXTERNAL'
    | 'UNSIGNED_COMMITMENT_LETTER'
    | 'OTHER_INCOME_DOCUMENT'
    | 'OTHER_IDENTIFICATION_DOCUMENT'
    | 'OTHER_PROPERTIES_DOCUMENT'
    | 'OTHER_FINANCIALS_DOCUMENT';

export type DocumentCategory =
    | 'INCOMES'
    | 'IDENTIFICATION'
    | 'FINAL_DOCUMENTS'
    | 'FINANCIALS'
    | 'PROPERTIES'
    // Others
    | 'OTHER';

export type DocumentSubCategory =
    // INCOMES
    | 'INCOMES'
    | 'INCOMES_EMPLOYMENTS'
    | 'INCOMES_OTHER_INCOMES'
    | 'INCOMES_PENSIONS'
    | 'INCOMES_SELF_EMPLOYED'
    // IDENTIFICATION
    | 'IDENTIFICATION_NO_PIC'
    | 'IDENTIFICATION_WITH_PIC'
    // FINANCIALS
    | 'FINANCIALS_ASSETS'
    | 'FINANCIALS_LIABILITIES'
    | 'FINANCIALS_OTHER_FINANCIALS'
    // PROPERTIES
    | 'PROPERTIES'
    | 'PROPERTIES_OTHER_PROPERTY'
    | 'PROPERTIES_OTHER_PROPERTIES'
    | 'PROPERTIES_SUBJECT_PROPERTY'
    // FINAL
    | 'FINAL_NESTO_REQUIRED'
    | 'FINAL_LENDER_REQUIRED'
    | 'FINAL_OTHER'
    // Others
    | 'OTHERS';

export type DocumentCounts = ApplicantDocumentCount & {
    countsStateByApplicantId: Record<number, ApplicantDocumentCount>;
};

export type ApplicantDocumentCount = {
    allDocsApproved: boolean;
    allReceived: boolean;
    brokerApprovedCount: number;
    brokerUnapprovedCount: number;
    changesRequired: boolean;
    disabledCount: number;
    emptyCount: number;
    lenderAcceptedCount: number;
    missingDocuments: boolean;
    receivedCount: number;
    totalCount: number;
};

// Grouped Documents
export type GroupedDocuments = Record<DocumentCategory, GroupedDocumentsEntity>;

export type GroupedDocumentsEntity = Record<
    number | 'undefined',
    GroupedDocumentsSubCategory
>;

export type GroupedDocumentsSubCategory = Record<
    DocumentSubCategory,
    ApplicantDocument[]
>;

export type DocumentEntityType =
    | 'asset'
    | 'incomeEmployment'
    | 'incomeOther'
    | 'incomePension'
    | 'property';

export type DocumentEntityDetails = {
    path: (string | number)[];
    documentEntityType?: DocumentEntityType;
    documentEntity?:
        | ApplicantAsset
        | OtherProperty
        | SubjectProperty
        | ExistingIncomeOther
        | IncomeEmployment
        | RegisteredAddress;
};

export type DocumentTypesData = {
    category: DocumentCategory;
    subCategory: DocumentSubCategory;
    visibility: DocumentVisibility;
};

export type DocumentTypesResponse = Record<DocumentType, DocumentTypesData>;

export type BoostEmailInfo = {
    ApplicantID: number;
    ApplicationID: number;
    BrokerID: number;
    Documents: Document[];
    Sent: string;
};

export type DocumentLanguage = '' | 'en' | 'fr'; // if "", defaults to en if null or ""

export interface EscalationEmailRequestBody {
    includeApplicantDocs: boolean;
    documentLanguage: DocumentLanguage;
}

export type DownloadableDocumentsResponse = {
    url: string;
    expiry: string;
    originalFileName: string;
    contentType: string;
    created: string;
    updated: string;
};

export type DocumentValidation = {
    id: string;
    valid: boolean;
    lastModified?: string;
    lastModifiedBy?: { id: ApplicantInfo['applicantId'] } & Pick<
        ApplicantInfo,
        'email' | 'firstName' | 'lastName'
    >;
    i18n: {
        en: string;
        fr: string;
    };
};

export type DocumentValidationUpdate = Pick<DocumentValidation, 'id' | 'valid'>;

export enum DocumentVisibility {
    EXTERNAL = 'EXTERNAL',
    INTERNAL = 'INTERNAL',
}

export type DownloadClosingDocumentToDocCentrePayload = {
    entityId?: number;
} & Pick<
    ApplicantDocument,
    'applicationId' | 'applicantId' | 'documentType' | 'year'
>;

export type DownloadClosingDocumentToDocCentreOptions = {
    applicantsId: number[];
} & Omit<DownloadClosingDocumentToDocCentrePayload, 'applicantId'>;
